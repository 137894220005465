import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout pageTitle="Conference & Awards - Press Releases">
            <Row>
                <Col>
                    <Link to="/conference">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}></Col>
            </Row>
            <ValueRow
                name="Alternate Quotes from Angela"
                href="https://docs.google.com/document/d/18hstbx2CzE04O-bELphZBFysoWDXm4u-zqS7q1l2ZGE/edit?usp=sharing"
            />
            <ValueRow
                name="Press Invite"
                href="https://docs.google.com/document/d/1V99K2H2kjU1EvEkXho5heYXDARsJ5at12hVTm6LxJMo/edit?usp=sharing"
            />
              <ValueRow
                name="General Promotion Email"
                href="https://docs.google.com/document/d/1Of5svxu81FB9_gQk_9V3X0QfxVwU-Tc8dpCtVERSWRk/edit?usp=sharing"
            />
              <ValueRow
                name="Workshop Promotion Email"
                href="https://docs.google.com/document/d/1165cYgdT2ds4xeAAVlAKTheIGzhbTMyS29LQYry8vLQ/edit?usp=sharing"
            />
              <ValueRow
                name="Expert Pod Promotion Email"
                href="https://docs.google.com/document/d/1IukZnuoXIjOgg7b2kCsMXyoomxpI4IwyyxPXum7Qi_U/edit?usp=sharing"
            />
              <ValueRow
                name="General Conference Press Release"
                href="https://docs.google.com/document/d/1DE3MzF755qeAaPhYyy8XUJPlGbdbSoTIUGP326DJ9gc/edit?usp=sharing"
            />
            
            
        </Layout>
    )
}

const ValueRow = ({ name, href }) => {
    return (
        <Row className="mt-5">
            <Col>
                <h3>{name}</h3>
                <a
                    href={href}
                    className="btn btn-primary m-3"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Download Document
                </a>
            </Col>
        </Row>
    )
}

export default IndexPage
